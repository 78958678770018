import React from 'react';
import { Grid } from '@mui/material';
import './Incubation.css';
import MainCard from 'ui-component/cards/MainCard';
import FormN from './FormN';
import VerticalLinearStepper from './Stepper';
import Incubation from './Incubation';

const Incubations = () => {
    return (
        <Grid>
            <Grid>
                <Grid container className="incback" sx={{ minHeight: '400px', width: '100%' }}></Grid>
            </Grid>
            <VerticalLinearStepper />
        </Grid>
    );
};

export default Incubations;
