import React from 'react';
import { Grid, RadioGroup, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import './Stage5.css';
import { ideaArray } from './Context';
import axios from 'axios';

const Stage5N = () => {
    const Input = styled('input')({
        display: 'none'
    });

    const {data, setData} = React.useContext(ideaArray)
    

    // const [Choose, setChoose] = React.useState('');
    const [file, setFile] = React.useState(null)
    // const handleChange = (event) => {
    //     setChoose(event.target.value);
    // };
    return (
        <Grid container xs={12} sx={{ marginTop: '4%' }}>
            <Grid xs={12} sx={{justifyContent: 'center', alignItems: 'flex-start', margin: '0 0 0 -5%'}}>
                <Typography variant="h2" textAlign="center" lineHeight="2" color="black" fontWeight="bold">
                    Expansion of the Product
                </Typography>
                <Box
                    component="form"
                    sx={{
                        textAlign: 'center',
                        '& > :not(style)': { m: 3}
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <TextField id="outlined-basic" label="Outlined" variant="outlined" sx={{minWidth: '60%'}} value={data.outline} 
                    onChange={e=>{setData({...data,outline:e.target.value})}}
                    />
                </Box>
            {/* <Grid className="rocket4" xs={6}> */}
                {/* <Grid xs={12} sx={{ textAlign: 'center', margin: '0% 0% 3% 5%' }}>
                    <Typography variant="h5" color="black" fontWeight="bold">
                        Ready to launch?
                    </Typography>
                </Grid> */}
                <Grid xs={12}  sx={{  marginLeft: '45%', justifyContent: 'center', alignItems: 'center' }} htmlFor="contained-button-file">
                <FormLabel>
                    <input type={'file'} accept="*" style={{display: "none"}} onChange={e=>{
                        setData({...data,expansion:e.target.files[0]})
                    }}/>
                    <Button variant="contained"  component="span" sx={{
                        background: data.expansion != null ? 'green' : '#2196f3'
                    }}>
                    Upload
                    </Button>
                </FormLabel>
            </Grid>
                </Grid>
            {/* </Grid> */}
        </Grid>
    );
};

export default Stage5N;