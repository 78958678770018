import React, { useContext } from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { Typography, Grid } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Stage3N from './Stage3N';
import './Stage5.css';
import { formContext, ideaArray } from './Context';

const Stage2N = () => {
    const Input = styled('input')({
        display: 'none'
    });

    const {data, setData} = React.useContext(ideaArray)
    const formData = useContext(formContext)
    

    const [file, setFile] = React.useState(null)
    return (
        <Grid container sx={{margin: '0 0 0 7%'}}>
            <Grid lg={12} xs={12} md={12} sm={12} container >
                <Grid lg={7} xs={12} md={7} sm={7}>
                    <Typography variant="h2" textAlign="center" lineHeight="100px" color="black" fontWeight="bold">
                        Idea Overview
                    </Typography>
                </Grid>

                <Grid sx={{ marginTop: '2% 0% 0% 0%' }} lg={12} xs={12} md={12} sm={12}>
                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                        <Grid container  onChange={e=>{setFile(e.target.value)}}>
                            <Grid lg={2} />
                            <Grid lg={2} xs={6} md={3} sm={3}>
                                <FormControlLabel sx={{ fontWeight: 'bold' }} value="audio/*" control={<Radio />} label="Audio" />
                            </Grid>
                            <Grid lg={2} xs={6} md={3} sm={3}>
                                <FormControlLabel sx={{ fontWeight: 'bold' }} value="video/*" control={<Radio />} label="Video" />
                            </Grid>
                            <Grid lg={2} xs={6} md={3} sm={3}>
                                <FormControlLabel
                                    sx={{ fontWeight: 'bold' }}
                                    value=".ppt,.pptx,.pptm"
                                    control={<Radio />}
                                    label="Presentation"
                                />
                            </Grid>
                            <Grid lg={2} xs={6} md={3} sm={3}>
                                <FormControlLabel sx={{ fontWeight: 'bold' }} value="application/pdf" control={<Radio />} label="PDF" />
                            </Grid>
                        </Grid>
                        <Grid lg={2}  md={8} sm={8} direction="row" sx={{ textAlign: 'center' }}>
                            {/* <label htmlFor="contained-button-file"> */}
                                {/* <Input accept="image/*" id="contained-button-file" multiple type="file" /> */}
                                <FormLabel>
                                <input type={'file'} accept={file} style={{display: "none"}} onChange={e=>{
                                    setData({...data,ideaVideo:e.target.files[0]})
                                }}/>
                                <Button variant="contained" component="span" sx={{
                                    background: data.ideaVideo != null ? 'green' : '#2196f3'
                                }}>
                                    Upload
                                </Button>
                                </FormLabel>
                            {/* </label> */}
                        </Grid>
                    </RadioGroup>
                </Grid>
            </Grid>
            {/* <Grid className="rocket1" lg={6}></Grid> */}
        </Grid>
    );
};

export default Stage2N;
