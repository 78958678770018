import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { InputLabel, } from '@mui/material';
import React from 'react';
import { useContext, useState } from 'react';
import { Grid } from '@mui/material';
import jsonList from './centers.json';
import { Address } from './RegisterStep2';



export default function Supportcenter({ data, setdata }) {
	// const {add,setAdd}=useContext(Address);
	const [add, setAdd] = useState("")
	const [sc, setSc] = useState(0)
	const [cityArr, setCityArr] = React.useState(['none']);
	

	const handleChange = (e) => {
		let cityData = e.target.value.split(" ")
		let city1 = cityData
		// setAdd({...add,city:city1})
		setdata({ ...data, userIdSC: city1[0], userIdAP: city1[1] })
	}
	

	return (
		<Grid container item xs={12}>
			<Grid item xs={12} lg={6} marginTop="2%">
				<Grid item xs={12}></Grid>
				<FormControl
					sx={{
						display: 'flex'
					}}
					fullWidth
				>
					<InputLabel>State</InputLabel>
					<Select label="State" sx={{ width: '99%', height: '50px' }} value={add.country} onChange={(e) => setAdd({ ...add, country: e.target.value })}>
						{jsonList.Countries.map((country, countryIndex) => {
							return (
								<MenuItem
									onClick={() => {
										setSc(countryIndex)
									}}
									value={country.CountryName}
									key={country.CountryName}
								>
									{country.CountryName}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</Grid>

			<Grid item xs={12} lg={6} marginTop="2%">
				<Grid item xs={12}></Grid>
				<FormControl
					sx={{
						display: 'flex'
					}}
					fullWidth
				>
					<InputLabel>Zone</InputLabel>
					<Select label="Zone" sx={{ width: '99%', height: '50px' }}
						value={add.state}
						onChange={(e) => setAdd({ ...add, state: e.target.value })}>
						{jsonList.Countries[sc].States.map((state, stateIndex) => {
							return (
								<MenuItem
									onClick={() => {
										setCityArr(jsonList.Countries[sc].States[stateIndex].Cities);
										// setState(stateArr[stateArrIndex].StateName)
									}}
									value={state.StateName}
									key={state.StateName}
								>
									{state.StateName}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</Grid>
			<Grid item xs={12} lg={6} marginTop="2%">
				<FormControl
					sx={{
						marginBottom: '3%'
					}}
					fullWidth
				>
					<InputLabel>Support Center</InputLabel>
					<Select
						label="Support Center"
						sx={{ width: '99%', height: '50px' }}
						// value={add.city}
						onChange={(e) => {
							handleChange(e);

							// setCity(e.target.value);
						}}
					>
						{cityArr?.map((city, cityIndex) => {
							return (
								<MenuItem value={city.UserIdSC + " " + city.UserIdAP} key={city.Address}>
									{city.Address}
								</MenuItem>
							);
						})}
					</Select>
				</FormControl>
			</Grid>
		</Grid>
	);
}

