import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import React from "react";

export const menuu = [
  {
    title: "Technical Analysis",
    items: [
      {
        icon: <PlayArrowIcon />,
        title: "The Dow Theory",
        to: "/thedowtheory"
      },
      {
        icon: <PlayArrowIcon />,
        title: "Charts & Chart Patterns",
        to: "/chart"
      },
      {
        icon: <PlayArrowIcon />,
        title: "Trend & Trend Lines",
        to: "/trendlines"
      },
      {
        icon: <PlayArrowIcon />,
        title: "Support & Resistance",
        to: "/sandr"
      }
    ]
  },
  {
    title: "Fundamental Analysis",
    items: [
      {
        icon: <PlayArrowIcon />,
        title: "The Dow Theory",
        to: "/thedowtheory"
      },
      {
        icon: <PlayArrowIcon />,
        title: "Charts & Chart Patterns",
        to: "/chart"
      },
      {
        icon: <PlayArrowIcon />,
        title: "Trend & Trend Lines",
        to: "/trendlines"
      },
      {
        icon: <PlayArrowIcon />,
        title: "Support & Resistance",
        to: "/sandr"
      }
    ]
  },
  {
    title: "Elliot Wave Analysis",
    items: [
      {
        icon: <PlayArrowIcon />,
        title: "The Dow Theory",
        to: "/thedowtheory"
      },
      {
        icon: <PlayArrowIcon />,
        title: "Charts & Chart Patterns",
        to: "/chart"
      },
      {
        icon: <PlayArrowIcon />,
        title: "Trend & Trend Lines",
        to: "/trendlines"
      },
      {
        icon: <PlayArrowIcon />,
        title: "Support & Resistance",
        to: "/sandr"
      }
    ]
  }
]
