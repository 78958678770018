import * as React from 'react';
import AddIcon from '@mui/icons-material/Add';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/styles';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import RemoveIcon from '@mui/icons-material/Remove';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';

const StyledListItemButton = styled(ListItemButton)({
    background: '#f5f5f5',
    margin: '12px 0 12px 18px',
    width: '450px',
    height: '60px',
    border: '2px',
    borderColor: 'black',
    borderRadius: '4px'
});

const StyledParentListItemButton = styled(ListItemButton)({
    background: '#f2f7fc',
    width: '500px',
    height: '65px',
    borderRadius: '4px',
    margin: '12px 0px'
});

export default function NestedList({ heading, data, subdata }) {
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);

    const handleClick = () => {
        setOpen(!open);
    };
    const handleClick1 = () => {
        setOpen1(!open1);
    };

    return (
        <div>
            <StyledParentListItemButton onClick={handleClick} sx={{minWidth: '600px', maxHeight: '600px'}}>
                <ListItemIcon>
                    {open === false ? <AddIcon sx={{ mr: 1, color: 'black' }} /> : <RemoveIcon sx={{ mr: 1, color: 'black' }} />}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Mulish' }}>{heading}</ListItemText>
                {open ? <ExpandLess /> : <ExpandMore />}
            </StyledParentListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {data.map((obj, index) => {
                        return (
                            <First obj={obj} />
                        );
                    })}
                </List>
            </Collapse>
        </div>
    );
}

function First({ obj }) {

    const [open1, setOpen1] = React.useState(false)
    const handleClick1 = () => {
        setOpen1(!open1);
    };

    return (
        <div>
            <StyledListItemButton sx={{ pl: '14px', minWidth: '580px' }} onClick={handleClick1}>
                <ListItemIcon>
                    {obj.state === 0 ? (
                        <AccessTimeFilledIcon sx={{ mr: 1, color: 'black' }} />
                    ) : obj.state === 1 ? (
                        <OndemandVideoIcon sx={{ mr: 1, color: 'black' }} />
                    ) : (
                        <PlaylistAddCheckIcon sx={{ mr: 1, color: 'black' }} />
                    )}
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Mulish' }}>{obj.title}</ListItemText>
                {open1 ? <ExpandLess /> : <ExpandMore />}
            </StyledListItemButton>
            <Collapse in={open1} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {obj.subdata.map((sobj, index) => {
                        return (
                            <StyledListItemButton key={index} sx={{ pl: '14px' }}>
                                <ListItemIcon>
                                    {sobj.state === 0 ? (
                                        <PlayArrowIcon sx={{ mr: 1, color: 'black' }} />
                                    ) : sobj.state === 1 ? (
                                        <PlayArrowIcon sx={{ mr: 1, color: 'black' }} />
                                    ) : (
                                        <PlayArrowIcon sx={{ mr: 1, color: 'black' }} />
                                    )}
                                </ListItemIcon>
                                <ListItemText primaryTypographyProps={{ fontSize: '16px', fontWeight: 500, fontFamily: 'Mulish' }}>{sobj.subtitle}</ListItemText>
                            </StyledListItemButton>

                        );
                    })}
                </List>
            </Collapse>
        </div>
    )
}