import React from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Grid, RadioGroup, Typography } from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import { fontFamily } from '@mui/system';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import './Stage5.css';
import { ideaArray } from './Context';

const Stage3N = () => {
    const Input = styled('input')({
        display: 'none'
    });
    // const [Choose, setChoose] = React.useState('');
    const {data, setData} = React.useContext(ideaArray)


    // const handleChange = (event) => {
    //     setData({...data,completionStatus:event.target.value});
    // };
    // const [file, setFile] = React.useState(null)
    return (
        <Grid container>
            <Grid xs={12} sx={{ marginTop: '4%' }}>
                <Grid xs={12} sx={{ margin: '3% 0% 0 -5%' }}>
                    <Typography variant="h2" textAlign="center" fontWeight="bold">
                        Completion Status
                    </Typography>
                    <Grid xs={12} sx={{ margin: '3% 0%', textAlign: 'center' }}>
                        <Select
                            sx={{ width: '65%' }}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={data.completionStatus}
                            label="Choose option"
                            onChange={e=>{setData({...data,completionStatus:e.target.value})}}
                        >
                            <MenuItem value="Completed">Complete working prototype</MenuItem>
                            <MenuItem value="Under Progress">Under progress</MenuItem>
                        </Select>
                    </Grid>
                    <Grid xs={12} sx={{ textAlign: 'center', margin: '3% 0%' }}>
                        <Typography variant="h3">Working Model Demo</Typography>
                    </Grid>

                    <Grid xs={12} sx={{ textAlign: 'center', margin: '3% 0%' }}>
                    <FormLabel>
                                <input type={'file'} accept="*" style={{display: "none"}} onChange={e=>{
                                    setData({...data,modal:e.target.files[0]})
                                }}/>
                                <Button variant="contained" component="span" sx={{
                                    background: data.modal != null ? 'green' : '#2196f3'
                                }}>
                                    Upload
                                </Button>
                                </FormLabel>
                    </Grid>
                </Grid>
            </Grid>
            {/* <Grid className="rocket2" xs={6}></Grid> */}
        </Grid>
    );
};

export default Stage3N;