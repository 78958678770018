import React from 'react';
import { Grid, RadioGroup, Typography } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import './Stage5.css';
import { data } from 'views/dashboard/Scheduler/Grouping';
import { ideaArray } from './Context';

const Stage4N = () => {
    const Input = styled('input')({
        display: 'none'
    });

    const {data, setData} = React.useContext(ideaArray)

    // const [file, setFile] = React.useState(null)
    // const [Choose, setChoose] = React.useState('');

    // const handleChange = (event) => {
        // setChoose(event.target.value);
    // };
    return (
        <Grid container sx={{ margin: '1% 0% 0 5%' }}>
            <Grid xs={8} columnSpacing={12} textAlign="center">
                <Typography variant="h2" lineHeight="2" fontWeight="bold">
                    Expenses and Revenue Model
                </Typography>
                <FormControl>
                    {/* <Grid xs={12}> */}
                        <FormLabel>
                            <input type={'file'} accept="*" style={{ display: "none" }} onChange={e => {
                                setData({...data,revenue:e.target.files[0]})
                            }} />
                            <Button variant="contained" component="span" sx={{
                                background: data.revenue != null ? 'green' : '#2196f3'
                            }}>
                                Upload
                            </Button>
                        </FormLabel>
                    {/* </Grid> */}
                </FormControl>
            </Grid>
            {/* <Grid className="rocket3" xs={6}></Grid> */}
        </Grid>
    );
};

export default Stage4N;