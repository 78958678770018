import { List, Grid, IconButton, Collapse } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import NestedList from './NestedList';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import Week from 'Week/Week';
export default function LatestUpdates({ courseContent }) {


    



    return (
        <Grid lg={11} xs={12}>
            <Week courseId={courseContent}/>
        </Grid>
    );
}

const ModTile = ({ topicArray, mod }) => {
    const [open, setOpen] = useState(false)
    const handleClick = () => {
        setOpen(!open)
    }
    return (
        <>

            <Box
                key={mod.id}
                onClick={handleClick}
                sx={{
                    width:"100%",
                    height: "50px",
                    backgroundColor: "rgba(1, 105, 194, 0.05)",
                    borderRadius: "6px",
                    marginBottom: "6px",
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center'
                }}>

                {open ? <RemoveIcon sx={{ color: "#5C6BFF" }} /> : <AddIcon sx={{ color: "#5C6BFF" }} />}

                <span style={{margin: '0 0 0 2%'}}>{mod.name}</span>



            </Box>
            <Collapse in={open}>
                {
                    topicArray.map((topic, topicIndex) => {
                        return (
                            <TopicTile topic={topic} key={topic.id + topicIndex} subTopicArray={topic.subTopics} />
                        )
                    })
                }

            </Collapse>


        </>
    )
}


const TopicTile = ({ subTopicArray, topic }) => {
    const [open1, setOpen1] = useState(false)
    const handleClick1 = () => {
        setOpen1(!open1)
    }
    return <>
        <Box
            key={topic.id}
            onClick={handleClick1}
            sx={{
                width:"100%",
                height: "50px",
                backgroundColor: "rgba(1, 105, 194, 0.05)",
                borderRadius: "6px",
                marginBottom: '6px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}>
            {open1 ? <RemoveIcon sx={{ color: "#5C6BFF" }} /> : <AddIcon sx={{ color: "#5C6BFF" }} />}

            

            <span style={{margin: '0 0 0 2%'}}>{topic.name}</span>


        </Box>
        <Collapse in={open1}>
            {
                subTopicArray.map((subTopic, subTopicIndex) => {
                    return (
                        <SubTopicTile subTopic={subTopic} key={subTopic.id + subTopicIndex} />
                    )
                })
            }

        </Collapse>
    </>
}


const SubTopicTile = ({ subTopic }) => {
    return <>
        <Box
            key={subTopic.id}
            sx={{
                width: "480px",
                height: "50px",
                backgroundColor: "#fff",
                borderRadius: "6px",
                marginLeft: "20px",
                flexDirection:'column',
                padding: "2% 0 0 2%",
                justifyContent: 'flex-start',
                alignItems: 'center'
            }}>
            <PlayArrowIcon sx={{backgroundColor: '#5C6BFF', color: '#fff', borderRadius: '5px', margin: '0 0 0 -2%'}}/>
            <span style={{marginLeft: '2%'}}>{subTopic.name}</span>

        </Box>
    </>
}