/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { Grid, Link } from '@mui/material';
import MuiTypography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
// project imports
import SubCard from 'ui-component/cards/SubCard';
import MainCard from 'ui-component/cards/MainCard';
import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import { gridSpacing } from 'store/constant';
import { DataGrid } from '@mui/x-data-grid';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import DropdownButton from './DropdownButton';
import UnstyledInput from './TextInput';
import MyCourses from './MyCourses';
import CurrentLanding from './CurrentCourses/CurrentLanding';
import CourseLayout from './CurrentCourses/CourseLayout';
// ==============================|| TYPOGRAPHY ||============================== //


const Typography1 = (page) => {
    const [val, setVal] = useState(page);
    return (
        <>
            <Grid container>
                {val != 1 ? <CurrentLanding /> : <CourseLayout />}
            </Grid>
        </>
    );
};

export default Typography1;
