// import { Button } from '@mui/material';
import { Typography } from '@mui/material';
import React, { useState } from 'react';

function DescriptionCourse({ data }) {
    const [readMore, setReadMore] = useState(false);
    const extraContent = <div>
        <p className="extra-content">
            {
                data.length > 105 && !readMore  ? (
                    data.substring(0,104) + "..."
                ) : (
                    data
                )
            }
        </p>
    </div>
    const linkName = readMore ? 'Show Less' : 'Show More'
    return (
        <>
            <Typography sx={{ fontFamily: 'Roboto', letterSpacing: '0.8', fontSize: '14px', textAlign: 'justify' }}>{extraContent}</Typography>
            <Typography className="Description" sx={{ color: '#2196f3', float: "right", fontFamily: 'Roboto', letterSpacing: '0.8', fontSize: '14px', cursor: 'pointer', marginTop: '-1%' }} onClick={() => { setReadMore(!readMore) }}>
                {linkName}
            </Typography>
        </>
    );
}

export default DescriptionCourse;
