import { Button, Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box} from "@mui/material"
import ErrorIcon from '@mui/icons-material/Error';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useState } from 'react'
import styled from "@emotion/styled";
import TodayIcon from '@mui/icons-material/Today';
import CircleIcon from '@mui/icons-material/Circle';
import { NavLink} from "react-router-dom";


const Button1 = styled(Button)({
  color:'#1a50b2',
  backgroundColor: "#edf1fd"
})


function createData(name, status, date, submit, view, report) {
    return { name, status, date, submit, view, report };
}

const rows = [
    createData('CreatBell', <Box sx={{backgroundColor:"#E1FCEF" ,height:'20px',width:'96px',borderRadius:'4px',color:'#14804A'}}><CircleIcon sx={{width:'10px',height:'10px'}}/>Open</Box>, "-", <Button>Submit Now</Button>, 'View', <Button>Download</Button>),
    createData('Good Learning Software Services', <Box sx={{backgroundColor:"#E1FCEF" ,height:'20px',width:'96px',borderRadius:'4px',color:'#14804A'}}><CircleIcon sx={{width:'10px',height:'10px'}}/>Open</Box>, "-", <Button>Submit Now</Button>, 'View', '-'),
    createData('Learn Ahead', <Box sx={{backgroundColor:"#E1FCEF" ,height:'20px',width:'96px',borderRadius:'4px',color:'#14804A'}}><CircleIcon sx={{width:'10px',height:'10px'}}/>Open</Box>, "-", <Button>Submit Now</Button>, 'View', <Button>Download</Button>),
    createData('Learn Ahead Test Series', <Box sx={{backgroundColor:"#E1FCEF" ,height:'20px',width:'96px',borderRadius:'4px',color:'#14804A'}}><CircleIcon sx={{width:'10px',height:'10px'}}/>Open</Box>, "-", <Button>Submit Now</Button>, 'View', '-'),
    // createData('Company 5', <Box sx={{backgroundColor:"#FFEDEF" ,height:'20px',width:'96px',borderRadius:'4px',color:'#D1293D'}}><CircleIcon sx={{width:'10px',height:'10px'}}/>Rejected</Box>, "-", <Button>Submit Now</Button>, 'View', <Button>Download</Button>),
    // createData('Company 6', <Box sx={{backgroundColor:"#E1FCEF" ,height:'20px',width:'96px',borderRadius:'4px',color:'#14804A'}}><CircleIcon sx={{width:'10px',height:'10px'}}/>Accepted</Box>, "-", <Button>Submit Now</Button>, 'View', <Button>Download</Button>),
    // createData('Company 7', <Box sx={{backgroundColor:"#FFEDEF" ,height:'20px',width:'96px',borderRadius:'4px',color:'#D1293D'}}><CircleIcon sx={{width:'10px',height:'10px'}}/>Rejected</Box>, "-", <Button>Submit Now</Button>, 'View', <Button>Download</Button>),
    // createData('Company 8', <Box sx={{backgroundColor:"#E1FCEF" ,height:'20px',width:'96px',borderRadius:'4px',color:'#14804A'}}><CircleIcon sx={{width:'10px',height:'10px'}}/>Accepted</Box>, "-", <Button>Submit Now</Button>, 'View', '-')
];  


export default function Researchinfo() {
    const [open, setopen] = useState(false)
    const [readMore, setReadMore] = useState(false);

    return (
        <Grid container display='inline'sx={{backgroundColor:'#E5E5E5',marginTop:'40px'}} >
           <Grid container sx={{marginBottom:'2%'}}>
             
             <Grid item xs={12} lg={8}/>
                <Grid item xs={12} lg={4} sx={{display:'flex',justifyContent:'space-around'}}>
                <NavLink to={'/home'} style={{textDecoration:'none'}}><Button1 sx={{ height: "40px", width: "100px", fontSize: '1.1rem', backgroundColor: '#EDF1FD' }}><b>Home</b></Button1></NavLink>
                 <NavLink to={'/researchfeed'} style={{textDecoration:'none'}}><Button1 sx={{ height: "40px", width: "100px",fontSize: '1.1rem', backgroundColor: '#EDF1FD' }}><b>Feed</b></Button1></NavLink>
                 <NavLink to={'/researchprofile'} style={{textDecoration:'none'}}><Button1 sx={{ fontSize: '1.1rem', backgroundColor: '#EDF1FD' }}><b> Research Profile</b></Button1></NavLink>
                </Grid>
               
              </Grid>
            {/* <Grid item>
                <Typography variant='h4' textAlign={'center'}>Research Profile</Typography>
            </Grid> */}
           <Grid  item sx={{display:'flex'}}>
           <Grid sm={1}/>
            <Grid xs={10} sm={10} sx={{marginTop:'40px'}}>
                <Typography sx={{ fontFamily: 'Montserrat', fontSize: '18px', fontStyle: 'normal', fontWeight: '600', lineHeight: '25px', letterSpacing: '0.03em', textAlign: 'left' }}
                >Research Information</Typography>
                <Box sx={{ backgroundColor: '#177ACC', width: '100%', height: '3px',marginTop:'20px' }}></Box>
                <TableContainer component={Paper} >
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ color: '#687182' }} align="left">COMPANY <ArrowDropDownIcon sx={{ width: '18px', height: '18px' }} /></TableCell>
                                <TableCell sx={{ color: '#687182' }} align="center">STATUS <ErrorIcon sx={{ width: '14px', height: '14px' }} /></TableCell>
                                <TableCell sx={{ color: '#687182' }} align="center">APPLIED ON <ArrowDropDownIcon sx={{ width: '18px', height: '18px' }} /></TableCell>
                                <TableCell sx={{ color: '#687182' }} align="center">RESEARCH SUBMITTED</TableCell>
                                <TableCell sx={{ color: '#687182' }} align="center">PREVIEW</TableCell>
                                <TableCell sx={{ color: '#687182' }} align="center">REPORT</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => {
                                if (index > 4) {
                                    if(open){
                                        return (
                                            <TableRow
                                                key={row.name}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row" align="left">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell align="center">{row.status}</TableCell>
                                                <TableCell align="center" sx={{color:' #171C26'}}>{row.date}</TableCell>
                                                <TableCell align="center">{row.submit}</TableCell>
                                                <TableCell align="center"><Button>{row.view}</Button></TableCell>
                                                <TableCell align="center">{row.report}</TableCell>
                                            </TableRow>
                                        )
                                    }return null
                                }

                                return (
                                    <TableRow
                                        key={row.name}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row" align="left">
                                            {row.name}
                                        </TableCell>
                                        <TableCell align="center">{row.status}</TableCell>
                                        <TableCell align="center"> {row.date}</TableCell>
                                        <TableCell align="center">{row.submit}</TableCell>
                                        <TableCell align="center"><Button>{row.view}</Button></TableCell>
                                        <TableCell align="center">{row.report}</TableCell>
                                    </TableRow>
                                )
                            })}
                            

                        </TableBody>

                    </Table>

                </TableContainer>

                <Button variant="contained" onClick={() => {
                    setopen(!open)
                    setReadMore(!readMore)
                }} sx={{ marginTop: '20px',marginBottom:'30px' }}>{!readMore ? (<>View more</>) : (<>View Less</>)}</Button>
            </Grid>
           </Grid>
        </Grid>
    )
}

