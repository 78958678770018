import React, { useEffect } from 'react';
import { Button, Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { fontSize, typography } from '@mui/system';
import Business from '../../assets/images/studash/incubation/Business.png';
import Business1 from '../../assets/images/studash/incubation/Business1.png';
import './Incubation.css';
import AppleIcon from '@mui/icons-material/Apple';
import adobeIcon from '../../assets/images/studash/incubation/adobeIcon.webp';
import googleIcon from '../../assets/images/studash/incubation/googleIcon.png';
import FacebookIcon from '@mui/icons-material/Facebook';
import { NavLink, useNavigate } from 'react-router-dom';


const Incubation = () => {
    const navigate= useNavigate();

    useEffect(()=>{
        navigate('');
    },[])

    return (
        <Grid className="row">
            <Grid className="incubation" container sx={{ width: '100%', minHeight: '500px', justifyContent: 'center' }}>
                {/* <NavLink to={'/Incubations'} style={{ textDecoration: 'none', marginTop: '14%' }}> */}
                    <Button
                        variant="contained"
                        onClick={() => navigate('/Incubations')}
                        sx={{
                            backgroundColor: '#fdfdfd4f',
                            maxHeight: '40px',
                            marginTop: '20%',
                            fontSize: '1.875rem',
                            padding: '27px 30px',
                            "&:hover":{backgroundColor: '#fdfdfde3', color: 'GrayText'}
                        }}
                    >
                        INCUBATION
                    </Button>
                {/* </NavLink> */}
            </Grid>
            {/* <Grid container sx={{ justifyContent: 'space-around' }}>
                <Button variant="text" sx={{ color: 'black', fontSize: '1.875rem' }}>
                    <img src={googleIcon} alt='' width="30px" />
                </Button>
                <Button variant="text" sx={{ color: 'black', fontSize: '1.875rem' }}>
                    <FacebookIcon color='primary' fontSize='large' />
                </Button>
                <Button variant="text" sx={{ color: 'black', fontSize: '1.875rem' }}>
                    <img src={adobeIcon} alt='' width="30px" />
                </Button>
                <Button variant="text" sx={{ color: 'black', fontSize: '1.875rem' }}>
                    <AppleIcon color='action' fontSize='large'/>
                </Button>
            </Grid> */}

            <Grid container display={'flex'} marginTop="2%">
                <Grid xs={12} sm={5} margin={'2% 7%'}>
                    <Typography variant="h1" sx={{ fontFamily: 'revert', lineHeight: '1.167', color: '#17191D', marginBottom: "2%" }}>
                        Incubate Now
                    </Typography>

                    <Typography sx={{ fontFamily: 'inherit', color: '#000000', fontSize: '160%' }}>
                        Creativity applied appropriately brings fruitful innovation. Showcase your ideas to investors with us.
                    </Typography>
                </Grid>

                <Grid xs={12} sm={5}>
                    <img src={Business} alt="" style={{ width: '80%', marginLeft: '0%' }}></img>
                </Grid>
            </Grid>
            <Grid container className="reverse">
                <Grid xs={12} sm={5}>
                    <img src={Business1} alt="" style={{ width: '100%', marginLeft: '0%' }}></img>
                </Grid>

                <Grid xs={12} sm={6} marginTop={'10%'} paddingLeft={'10%'}>
                    <Typography variant="h1" sx={{ fontFamily: 'revert', lineHeight: '1.167', color: '#17191D', marginBottom: "2%" }}>
                        Showcase your Ideas
                    </Typography>

                    <Typography sx={{ fontFamily: 'inherit', color: '#000000', fontSize: '160%' }}>
                        Create your own business ideas and convert them into a great venture. Get monitoring and monetary support by us.<br></br>
                        Start Now.
                    </Typography>
                    
                    <Button
                        variant="contained"
                        onClick={() => navigate('/Incubations')}
                        sx={{
                            backgroundColor: '#2196f3',
                            maxHeight: '40px',
                            marginTop: '2%',
                            // fontSize: '1.875rem',
                            // padding: '27px 30px',
                            "&:hover":{backgroundColor: '#2186ff'}
                        }}
                    >
                        Submit Your Idea Now
                    </Button>
                </Grid>
            </Grid>
                 
        </Grid>
    );
};

export default Incubation;
