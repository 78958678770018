import React from 'react';
import { Typography, Card, Grid, Button, IconButton, ImageList, TextField, InputLabel, MenuItem, FormControl, Select } from '@mui/material';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import Incubation21 from '../../assets/images/studash/incubation/Incubation21.png';
import { Box } from '@mui/material'
import NFT from '../../assets/images/studash/incubation/NFT.png';
import AvatarImage from '../../assets/images/studash/incubation/AvatarImage.png';
import IncubationCard from './IncubationCard';
import { NavLink } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';


export default function HomePage2() {
 
  const [filter, setfilter] = React.useState("MOst Viewed");

const handleChange = (event) => {
  setfilter(event.target.value);}

    
   
  

    return (
      <Grid container>
        <Grid container sx={{ margin: '2% 2%' }}>
          <Grid item xs={5} lg={9}>
            <Typography variant='h1' color={"#616161"}><b>Submitted Ideas</b></Typography>
          </Grid>

          {/* <Grid item xs={4} lg={2}>
            <NavLink to={'/incubhome'} style={{ textDecoration: 'none' }}> <Button variant='outlined' sx={{ borderRadius: '20px' }}>Back to homepage</Button></NavLink>
          </Grid>

          <Grid item xs={3} lg={1}>
            <IconButton>
              <NotificationsActiveOutlinedIcon />
            </IconButton>
          </Grid> */}
        </Grid>

        <div style={{ position: "relative", width: "100%", height: '400px' }}>
          <img
            src={Incubation21}
            alt="imagesa"
            style={{ width: "100%", height: '400px', position: "absolute" }}
          />
        </div>

        <Grid item xs={12} margin='2% 0 0 2%'>
          <Typography variant='h2' margin={"0 0 0 1%"} color={"#616161"}><b>All Ideas</b></Typography>
        </Grid>
        <Grid>
        <ImageList className='scroll' sx={{ display: "flex", marginTop: '50px' }} >
            <IncubationCard />
           
          </ImageList>
        </Grid>
      </Grid>
    )
  }