import * as React from 'react';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export default function BasicAlerts() {
  return (
    <Stack sx={{ width: '100%',fontSize:"20px",justifyContent:"center",align:"center"}} spacing={2}>
        <Box sx={{fontSize:"20px"}}>
      <Alert severity="success" sx={{fontSize:"20px"}}>Coming Soon...</Alert>
    </Box></Stack>
  );
}