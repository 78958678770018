import * as React from  'react'

export const UserContext = React.createContext({
    authenticated: false,
    user:{},
    setNewUser : ()=>{},
})

export const AssesmentContext = React.createContext({
    questions: [],
    responses: {},
    status: {},
    setNewContext: ()=>{}
})

export const IfPaidContext = React.createContext({
   
})